import React from "react"

import { Heading, Text, Center } from "@chakra-ui/layout"
import { Stack, Box, Image } from "@chakra-ui/core";
import chat from '../images/chat.png'
import chatJp from '../images/chat-jp.png'
import chatEn from '../images/chat-en.png'
import pv from '../images/pv.jpg'
import micro from '../images/micro.jpg'
import main from '../images/main.jpg'
import mainJp from '../images/main-jp.png'
import { useTranslation } from 'react-i18next';

const Help = () => {
  const { t, i18n: { language = 'en' } } = useTranslation();

  return (
    <Stack>
      <Text fontSize="xl" mb="16">{ t('help.description') }</Text>
      <Box mb="16">
        <Heading fontSize="xl" mb="6">{ t('help.item_1_ask') }</Heading>
        <Text>
        { t('help.item_1_response') }
        </Text>
        <Center>
          { language === 'en' && <Image src={chatEn} m="6"/> }
          { language === 'jp' && <Image src={chatJp} m="6"/> }
        </Center>
      </Box>
      <Box mb="16">
        <Heading fontSize="xl" mb="6">{ t('help.item_2_ask') }</Heading>
        <Text>
          { t('help.item_2_response') }
        </Text>
        <Center>
          { language === 'jp' && <Image src={mainJp} m="6"/> }
        </Center>
        <Center>
          { language === 'jp' && <Image src={micro} m="6"/> }
        </Center>
      </Box>
    </Stack>
  )
}

export default Help
